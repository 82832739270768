import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import Logo from '../assets/Logo.png'

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className='flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white' id='Navbar'>
      <a href="/" target="_blank" rel="noreferrer">
      <img class="object-contain h-14 w-30 m-5" src={Logo} alt="Logo" />
      </a>
      <ul className='hidden md:flex'>
      <a href="#newservices">
        <li className='p-4'>Our Services</li>
        </a>
        <a href="#Prices">
        <li className='p-4'>Prices</li>
        </a>
        <a href="#About">
        <li className='p-4'>Our Team</li>
        </a>
        <a href="#contact">
        <li className='p-4'>Contact</li>
        </a>
      </ul>
      <div onClick={handleNav} className='block md:hidden'>
          {nav ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20} />}
      </div>
      <ul className={nav ? 'fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500' : 'ease-in-out duration-500 fixed left-[-100%]'}>
          <img class="object-contain h-14 w-30 m-5" src={Logo} alt="Logo" />
          <li className='p-4 border-b border-gray-600'>Our Services</li>
          <li className='p-4 border-b border-gray-600'>Prices</li>
          <li className='p-4 border-b border-gray-600'>About Us</li>
          <li className='p-4 border-b border-gray-600'>Contact</li>
          <li className='p-4'>Contact</li>
      </ul>
      <a href="#contact">
      <button className='bg-cyan-400 w-[200px] rounded-md font-medium p-2 py-2 text-black hover:bg-cyan-400 active:bg-indigo-700 outline-none shadow-md focus:shadow-none focus:ring-2 ring-offset-2 ring-indigo-600 sm:mt-0 sm:ml-3 sm:w-auto transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300'>Request Free Page</button>
      </a>
    </div>
  );
};


export default Navbar;
