import React from "react";

const About = () => {
    const team = [
        {
            avatar: "https://randomuser.me/api/portraits/women/63.jpg",
            name: "Irene M.",
            title: "Digital Marketing Specialist",
            // desc: "4 years of agency digital marketing, social media marketing and content creation experience.",
            linkedin: "https://www.linkedin.com/in/irenemanolopoulos/",
            twitter: "javascript:void(0)",
        },
        {
            avatar: "https://media.licdn.com/dms/image/D5603AQHDKeuZV4Ps5A/profile-displayphoto-shrink_200_200/0/1692326169864?e=1699488000&v=beta&t=iCnEoC9nYDd9cY9oaDU7e4xSMmZozFpKdZwB0Bpu8vg",
            name: "Ruth Zota",
            title: "UX/UI Developer",
            desc: "",
            linkedin: "https://www.linkedin.com/in/ruthzota/",
            twitter: "javascript:void(0)",
        },
        {
            avatar: "https://media.licdn.com/dms/image/D5603AQGzMyCIPdQ5lw/profile-displayphoto-shrink_100_100/0/1693897642751?e=1700092800&v=beta&t=2v87BObVfdVNAVdJl05EmBkBdbbLVF7PoJ1Ie27YMWY",
            name: "Lisa Soto",
            title: "Social Media Marketer",
            desc: "",
            linkedin: "https://www.linkedin.com/in/lisa-soto-03690217a/",
            twitter: "javascript:void(0)",
        },



    ]

    return (
        <section className="py-14 text-black bg-gray-900" id='About'>
            <div className="max-w-screen-xl mx-auto px-4 text-center md:px-8">
                <div className="max-w-xl mx-auto">
    =
                    <h3 className=" text-3xl font-semibold sm:text-4xl text-white">
                       Team
                    </h3>

                </div>
                <div className="mt-12">
                    <ul className="grid gap-8 sm:grid-cols-2 md:grid-cols-3">
                        {
                            team.map((item, idx) => (
                                <li key={idx}>
                                    <div className="w-24 h-24 mx-auto">
                                        <img
                                            src={item.avatar}
                                            className="w-full h-full rounded-full"
                                            alt=""
                                        />
                                    </div>
                                    <div className="mt-2">
                                        <h4 className="font-semibold sm:text-lg text-white">{item.name}</h4>
                                        <p className="text-white">{item.title}</p>
                                        <p className="mt-2">{item.desc}</p>
                                        <div className="mt-4 flex justify-center gap-4 text-gray-400">

                                            <a href={item.linkedin}>
                                                <svg className="w-5 h-5 duration-150 text-cyan-400 hover:text-gray-500" fill="none" viewBox="0 0 48 48"><g clip-path="url(#clip0_17_68)"><path fill="currentColor" d="M44.447 0H3.544C1.584 0 0 1.547 0 3.46V44.53C0 46.444 1.584 48 3.544 48h40.903C46.407 48 48 46.444 48 44.54V3.46C48 1.546 46.406 0 44.447 0zM14.24 40.903H7.116V17.991h7.125v22.912zM10.678 14.87a4.127 4.127 0 01-4.134-4.125 4.127 4.127 0 014.134-4.125 4.125 4.125 0 010 8.25zm30.225 26.034h-7.115V29.766c0-2.653-.047-6.075-3.704-6.075-3.703 0-4.265 2.896-4.265 5.887v11.325h-7.107V17.991h6.826v3.13h.093c.947-1.8 3.272-3.702 6.731-3.702 7.21 0 8.541 4.744 8.541 10.912v12.572z" /></g><defs><clipPath id="clip0_17_68"><path fill="currentColor" d="M0 0h48v48H0z" /></clipPath></defs></svg>
                                            </a>
                                        </div>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </section>
    )
};

export default About;
