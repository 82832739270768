// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


const firebaseConfig = {
  apiKey: "AIzaSyAu36qbBkKRs2FgRn5SEDaGLtzon9QmJBk",
  authDomain: "ripple-tech-advisors-website.firebaseapp.com",
  databaseURL: "https://ripple-tech-advisors-website-default-rtdb.firebaseio.com",
  projectId: "ripple-tech-advisors-website",
  storageBucket: "ripple-tech-advisors-website.appspot.com",
  messagingSenderId: "583343718245",
  appId: "1:583343718245:web:2060c801620e5755286b8c",
  measurementId: "G-L1KN33JTH1"
};

// Use this to initialize the firebase App
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Use these for db & auth
const db = firebaseApp.firestore();
const auth = firebase.auth();

export { auth, db };