import React from 'react';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import About from './components/About';
import StatsBanner from './components/StatsBanner';
import Banner from './components/Banner';
import Projects from './components/Projects';
import NewPrices from './components/NewPrices';
import NewServices from './components/NewServices';
import TestimonialNew from './components/TestimonialNew';
import TestimonialNewFront from './components/TestimonialNewFront';
import Contact from './components/Contact';


function App() {
  return (
    <div>
      <Banner/>
      <Navbar />
      <Hero />
      <StatsBanner/>
      <NewServices/>
      <TestimonialNewFront/>
      <Projects/>
      <NewPrices/>
      <TestimonialNew/>
      <About />
      <Contact/>
      <Footer />
    </div>
  );
}

export default App;
