
import React from 'react';
import Typed from 'react-typed';
import { useState } from "react";
import { db } from "../firebase";



const Hero = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    db.collection('email')
    .add({
      email: email,
    })

    .then(() => {
      alert('Email has be submitted 👍')
    })
    .catch((error) => {
      alert(error.message);
    });

    setEmail("");
  };

  return (
    <div className='bg-[url("https://i.ibb.co/kcYPzry/pexels-cowomen-2041393.jpg")] text-white relative overflow-hidden bg-cover bg-no-repeat p-12 text-center' id='Hero'>
      <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
        <p className='text-[#FF6363] font-bold p-2'>
          {/* Design and Web Maintenance */}
        </p>
        <h1 className='md:text-6xl sm:text-6xl text-4xl font-bold md:py-6  text-red-400 hover:text-white active:bg-indigo-700'>
        #1 Veteran Small Business Marketers
        </h1>
        <div className='flex justify-center items-center'>
          <p className='md:text-5xl sm:text-4xl text-xl font-bold py-4'>

          </p>
          <Typed
          className='md:text-4xl sm:text-4xl text-xl font-bold md:pl-4 pl-2 text-cyan-400'
            strings={['Web Design', 'Website Maintenance', 'SEO Optimization', 'HTML Email']}
            typeSpeed={120}
            backSpeed={140}
            loop
          />
        </div>
        <p className='md:text-2xl text-xl font-bold text-white-400 py-2 mb-4'>
        An innovative small business marketing agency with a tech-focused approach that elevates your online presence.</p>

        <form
                    onSubmit={handleSubmit}
                    className="items-center justify-center sm:flex">
                    <input
                        type="email" value={email} onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        className="text-gray-500 w-full p-3 rounded-md border outline-none focus:border-indigo-600"
                    />
                    <button type="submit"
                        className="flex items-center justify-center gap-x-2 py-2.5 px-4 mt-3 w-full text-sm text-black font-medium bg-cyan-400 hover:bg-sky-400 active:bg-sky-600 duration-150 rounded-lg outline-none shadow-md focus:shadow-none focus:ring-2 ring-offset-2 ring-indigo-600 sm:mt-0 sm:ml-3 sm:w-auto transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110"
                    >
                        Contact
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-3 h-3">
                                    <path fillRule="evenodd" d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z" clipRule="evenodd" />
                                </svg>
                    </button>
                </form>
      </div>



    </div>

  );
};

export default Hero;
